import logo from './zigos-logo-cercle.png';
import favicon from './zigos-logo-mini.png'
import './App.css';
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="App">
		<Helmet>
			<meta charSet="utf-8" />
			<title>Le site de Monsieur Grisollet</title>
			<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
		</Helmet>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Pour accéder au Moodle, cliquez ici :
        </p>
        <a
          className="App-link"
          href="/moodle"
          rel="noopener noreferrer"
        >
          Moodle
        </a>
      </header>
    </div>
  );
}

export default App;
